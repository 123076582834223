import React from "react";

const PlayStoreIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="231"
      height="70"
      viewBox="0 0 231 70"
      fill="none"
      {...props}
    >
      <path
        d="M221.96 69.9405H9.6013C4.69401 69.9405 0.703979 65.9684 0.703979 61.049V8.89152C0.703979 3.98743 4.69401 0 9.6013 0H221.96C226.867 0 230.857 3.98743 230.857 8.89152V61.0643C230.857 65.9684 226.882 69.9405 221.96 69.9405Z"
        fill="black"
      />
      <path
        d="M76.1325 42.2573H79.3429V49.5294C78.4103 50.278 77.3096 50.8433 76.056 51.2558C74.8025 51.653 73.5183 51.8516 72.1883 51.8516C70.3232 51.8516 68.6416 51.4544 67.1587 50.6447C65.6606 49.835 64.4834 48.7197 63.6426 47.2989C62.7865 45.8781 62.3585 44.2587 62.3585 42.4712C62.3585 40.6837 62.7865 39.0796 63.6426 37.6435C64.4987 36.2227 65.6758 35.1075 67.174 34.2977C68.6722 33.488 70.3691 33.0908 72.2495 33.0908C73.7782 33.0908 75.1694 33.3505 76.4229 33.8547C77.6765 34.3589 78.7314 35.0922 79.5875 36.0699L77.4472 38.163C76.056 36.7727 74.3744 36.0699 72.4176 36.0699C71.1335 36.0699 69.9869 36.3449 68.9932 36.8796C67.9995 37.4144 67.2046 38.1782 66.639 39.156C66.0733 40.1338 65.7981 41.249 65.7981 42.4865C65.7981 43.7087 66.0733 44.7934 66.639 45.7712C67.2046 46.7489 67.9843 47.5128 68.9932 48.0628C69.9869 48.6128 71.1335 48.9031 72.4023 48.9031C73.8241 48.9031 75.0776 48.5975 76.1631 47.9711V42.2573H76.1325Z"
        fill="white"
      />
      <path
        d="M85.9471 50.8588C84.8158 50.2477 83.9292 49.4074 83.3024 48.3227C82.6603 47.238 82.3546 46.0005 82.3546 44.6255C82.3546 43.2506 82.6756 42.0284 83.3024 40.9437C83.9445 39.859 84.8158 39.0187 85.9471 38.4076C87.0784 37.7965 88.3473 37.4909 89.7537 37.4909C91.1754 37.4909 92.4596 37.7965 93.5909 38.4076C94.7221 39.0187 95.6088 39.859 96.2356 40.9437C96.8777 42.0284 97.1834 43.2506 97.1834 44.6255C97.1834 46.0005 96.8624 47.2227 96.2356 48.3227C95.5935 49.4074 94.7221 50.2629 93.5909 50.8588C92.4596 51.4699 91.1754 51.7754 89.7537 51.7754C88.3473 51.7754 87.0784 51.4699 85.9471 50.8588ZM92.7348 47.788C93.5144 46.9936 93.9119 45.9394 93.9119 44.6255C93.9119 43.327 93.5144 42.2728 92.7348 41.4631C91.9551 40.6687 90.9614 40.2562 89.7537 40.2562C88.546 40.2562 87.5676 40.6534 86.7879 41.4631C86.0083 42.2575 85.6261 43.3117 85.6261 44.6255C85.6261 45.9241 86.0083 46.9783 86.7879 47.788C87.5676 48.5824 88.546 48.9949 89.7537 48.9949C90.9614 48.9796 91.9398 48.5824 92.7348 47.788Z"
        fill="white"
      />
      <path
        d="M102.779 50.8588C101.647 50.2477 100.761 49.4074 100.134 48.3227C99.4918 47.238 99.186 46.0005 99.186 44.6255C99.186 43.2506 99.5071 42.0284 100.134 40.9437C100.776 39.859 101.647 39.0187 102.779 38.4076C103.91 37.7965 105.179 37.4909 106.585 37.4909C108.007 37.4909 109.291 37.7965 110.422 38.4076C111.554 39.0187 112.44 39.859 113.067 40.9437C113.709 42.0284 114.015 43.2506 114.015 44.6255C114.015 46.0005 113.694 47.2227 113.067 48.3227C112.425 49.4074 111.554 50.2629 110.422 50.8588C109.291 51.4699 108.007 51.7754 106.585 51.7754C105.179 51.7754 103.91 51.4699 102.779 50.8588ZM109.566 47.788C110.346 46.9936 110.743 45.9394 110.743 44.6255C110.743 43.327 110.346 42.2728 109.566 41.4631C108.787 40.6687 107.793 40.2562 106.585 40.2562C105.377 40.2562 104.399 40.6534 103.619 41.4631C102.84 42.2575 102.458 43.3117 102.458 44.6255C102.458 45.9241 102.84 46.9783 103.619 47.788C104.399 48.5824 105.377 48.9949 106.585 48.9949C107.793 48.9796 108.771 48.5824 109.566 47.788Z"
        fill="white"
      />
      <path
        d="M130.984 37.6586V49.4681C130.984 54.3722 128.477 56.8166 123.462 56.8166C122.117 56.8166 120.848 56.6485 119.656 56.3124C118.448 55.9763 117.47 55.4874 116.675 54.8458L118.142 52.4014C118.754 52.9055 119.518 53.3027 120.451 53.6083C121.383 53.9138 122.331 54.0666 123.279 54.0666C124.808 54.0666 125.939 53.7152 126.642 53.0277C127.361 52.325 127.712 51.2708 127.712 49.85V49.1167C127.162 49.7278 126.474 50.1861 125.679 50.4917C124.884 50.7972 123.997 50.9653 123.05 50.9653C121.735 50.9653 120.527 50.6903 119.457 50.125C118.387 49.5597 117.546 48.7653 116.919 47.7417C116.308 46.7181 115.987 45.5418 115.987 44.2279C115.987 42.914 116.293 41.7377 116.919 40.7141C117.531 39.6905 118.387 38.9113 119.457 38.3613C120.527 37.8113 121.72 37.5211 123.05 37.5211C124.059 37.5211 124.976 37.6891 125.801 38.0099C126.627 38.346 127.315 38.8502 127.88 39.5224V37.6738H130.984V37.6586ZM126.581 47.0848C127.376 46.3515 127.773 45.389 127.773 44.1973C127.773 43.0362 127.376 42.089 126.581 41.3557C125.786 40.6224 124.777 40.2557 123.539 40.2557C122.285 40.2557 121.261 40.6224 120.466 41.3557C119.671 42.089 119.274 43.0362 119.274 44.1973C119.274 45.3737 119.671 46.3362 120.466 47.0848C121.261 47.8181 122.285 48.2 123.539 48.2C124.777 48.2 125.801 47.8334 126.581 47.0848Z"
        fill="white"
      />
      <path d="M135.265 32.2354H138.521V51.592H135.265V32.2354Z" fill="white" />
      <path
        d="M155.857 45.6642H144.926C145.125 46.6878 145.614 47.4975 146.424 48.1086C147.235 48.7044 148.244 49.01 149.436 49.01C150.965 49.01 152.234 48.5058 153.227 47.4975L154.97 49.4989C154.343 50.2475 153.548 50.8127 152.601 51.1947C151.638 51.5766 150.567 51.7752 149.36 51.7752C147.831 51.7752 146.486 51.4697 145.308 50.8586C144.147 50.2475 143.245 49.4072 142.603 48.3225C141.961 47.2378 141.655 46.0003 141.655 44.6253C141.655 43.2656 141.961 42.0434 142.587 40.9587C143.199 39.874 144.055 39.0185 145.156 38.4227C146.256 37.8116 147.479 37.506 148.855 37.506C150.216 37.506 151.423 37.8116 152.494 38.4074C153.564 39.0032 154.405 39.8435 155.001 40.9435C155.597 42.0282 155.903 43.2809 155.903 44.717C155.903 44.9309 155.887 45.2517 155.857 45.6642ZM146.21 41.0351C145.492 41.6462 145.049 42.4712 144.896 43.4948H152.799C152.662 42.4865 152.234 41.6768 151.515 41.0504C150.797 40.424 149.91 40.1185 148.855 40.1185C147.816 40.1185 146.929 40.424 146.21 41.0351Z"
        fill="white"
      />
      <path
        d="M178.207 34.1144C179.384 34.6338 180.286 35.3824 180.913 36.3602C181.54 37.3379 181.846 38.4838 181.846 39.8282C181.846 41.1421 181.54 42.3031 180.913 43.2809C180.286 44.2587 179.384 45.0225 178.207 45.542C177.03 46.0614 175.654 46.3211 174.064 46.3211H169.937V51.5919H166.543V33.3352H174.064C175.654 33.3352 177.03 33.595 178.207 34.1144ZM177.275 42.517C178.039 41.8906 178.421 40.9893 178.421 39.8282C178.421 38.6671 178.039 37.7657 177.275 37.1393C176.51 36.513 175.394 36.2074 173.911 36.2074H169.952V43.4642H173.911C175.394 43.449 176.51 43.1434 177.275 42.517Z"
        fill="white"
      />
      <path d="M185.178 32.2354H188.434V51.592H185.178V32.2354Z" fill="white" />
      <path
        d="M202.759 38.9727C203.844 39.9504 204.395 41.4323 204.395 43.4184V51.5766H201.322V49.8808C200.924 50.4919 200.359 50.9502 199.61 51.2711C198.876 51.5919 197.974 51.7599 196.934 51.7599C195.895 51.7599 194.977 51.5766 194.198 51.2252C193.418 50.8738 192.807 50.3697 192.379 49.7433C191.951 49.1017 191.737 48.3989 191.737 47.5892C191.737 46.3364 192.195 45.3281 193.128 44.5795C194.06 43.8156 195.528 43.449 197.53 43.449H201.138V43.2351C201.138 42.2573 200.848 41.5087 200.267 40.9893C199.686 40.4699 198.815 40.2101 197.668 40.2101C196.888 40.2101 196.109 40.3324 195.36 40.5768C194.595 40.8212 193.969 41.1573 193.449 41.6004L192.165 39.2324C192.898 38.6824 193.77 38.2546 194.794 37.9491C195.818 37.6588 196.904 37.506 198.05 37.506C200.099 37.506 201.673 37.9949 202.759 38.9727ZM199.778 48.8419C200.42 48.4753 200.863 47.9406 201.123 47.2378V45.6184H197.76C195.879 45.6184 194.947 46.2295 194.947 47.467C194.947 48.0628 195.176 48.5211 195.65 48.8725C196.124 49.2239 196.766 49.3919 197.607 49.3919C198.417 49.3919 199.151 49.2086 199.778 48.8419Z"
        fill="white"
      />
      <path
        d="M221.348 37.6584L214.821 52.7067C214.209 54.2192 213.475 55.2886 212.604 55.8997C211.732 56.5108 210.678 56.8317 209.439 56.8317C208.736 56.8317 208.063 56.7247 207.376 56.4955C206.703 56.2664 206.137 55.9608 205.709 55.5636L207.009 53.165C207.314 53.4553 207.697 53.6998 208.125 53.8678C208.553 54.0359 208.981 54.1275 209.409 54.1275C209.99 54.1275 210.464 53.9748 210.83 53.6845C211.197 53.3942 211.549 52.8901 211.855 52.2026L212.084 51.6526L206 37.6736H209.393L213.781 47.9707L218.184 37.6736H221.348V37.6584Z"
        fill="white"
      />
      <path
        d="M56.0903 34.0836L47.3152 29.0267L40.0995 35.9933L46.6885 42.364L56.075 36.9405C56.5947 36.6502 56.9005 36.1002 56.9005 35.5044C56.9158 34.9085 56.5947 34.3738 56.0903 34.0836Z"
        fill="#FFD109"
      />
      <path
        d="M45.8324 28.1714L36.1707 22.5951L15.0281 11.7633L38.9225 34.8476L45.8324 28.1714Z"
        fill="#06EF79"
      />
      <path
        d="M15.3797 59.8724L36.2165 48.399L45.2056 43.2199L38.9224 37.1394L15.3797 59.8724Z"
        fill="#F93646"
      />
      <path
        d="M13.6218 12.6952L13.5912 59.307L37.7301 35.9934L13.6218 12.6952Z"
        fill="#05D5FB"
      />
      <path
        d="M68.1981 23.1908C67.4643 23.1908 66.7764 23.0381 66.1343 22.7172C65.4922 22.3964 64.9419 21.9686 64.468 21.4186C63.9941 20.8839 63.6272 20.2728 63.3673 19.5853C63.1074 18.9131 62.9698 18.2104 62.9698 17.477C62.9698 16.7132 63.1074 15.9951 63.3673 15.3229C63.6272 14.6507 63.9941 14.0396 64.468 13.5202C64.9419 13.0007 65.4922 12.5882 66.119 12.2827C66.7611 11.9771 67.449 11.8396 68.1981 11.8396C69.2835 11.8396 70.1702 12.0688 70.8887 12.5118C71.5919 12.9702 72.127 13.5507 72.4786 14.284L71.3932 15.0479C71.0722 14.391 70.6135 13.9174 70.0479 13.5965C69.4823 13.2757 68.8555 13.1229 68.1675 13.1229C67.6019 13.1229 67.0821 13.2452 66.6235 13.4896C66.1649 13.734 65.7674 14.0549 65.4311 14.4826C65.0947 14.8951 64.8501 15.3687 64.6667 15.8882C64.4985 16.4076 64.4068 16.9576 64.4068 17.5229C64.4068 18.1187 64.4985 18.684 64.6973 19.2187C64.896 19.7534 65.1712 20.2117 65.5228 20.6242C65.8744 21.0367 66.3025 21.3423 66.7764 21.5714C67.2503 21.8006 67.7548 21.9228 68.3051 21.9228C68.9472 21.9228 69.5587 21.7547 70.1396 21.4339C70.7205 21.1131 71.2709 20.6089 71.7754 19.9367V21.4034C70.7664 22.595 69.574 23.1908 68.1981 23.1908ZM71.6072 18.6076H69.1918V17.5381H72.7996V23.1297H71.6072V18.6076Z"
        fill="white"
      />
      <path
        d="M82.5684 21.8618V23.1298H74.9553V11.9161H82.4156V13.1841H76.377V16.8049H81.6359V17.9813H76.377V21.8465H82.5684V21.8618Z"
        fill="white"
      />
      <path
        d="M92.5971 13.1841H88.7294V23.1298H87.3077V13.1841H83.4399V11.9161H92.5971V13.1841Z"
        fill="white"
      />
      <path
        d="M97.7337 23.1145V11.9161H99.1555V23.1145H97.7337Z"
        fill="white"
      />
      <path
        d="M103.253 14.5438V23.1298H101.831V11.9161H103.023L109.872 20.6701V11.9314H111.294V23.1145H110.01L103.253 14.5438Z"
        fill="white"
      />
      <path
        d="M122.53 23.1911C121.735 23.1911 121.032 23.0383 120.39 22.7175C119.748 22.3966 119.197 21.9689 118.723 21.4341C118.249 20.8994 117.898 20.2883 117.638 19.6008C117.378 18.9286 117.256 18.2259 117.256 17.5078C117.256 16.7592 117.393 16.0412 117.653 15.369C117.928 14.6968 118.295 14.0857 118.769 13.5509C119.243 13.0162 119.809 12.6037 120.451 12.2829C121.093 11.9774 121.796 11.8246 122.545 11.8246C123.34 11.8246 124.043 11.9926 124.685 12.3135C125.328 12.6343 125.878 13.0773 126.337 13.6273C126.795 14.1773 127.162 14.7884 127.407 15.4606C127.667 16.1328 127.789 16.8203 127.789 17.5231C127.789 18.2717 127.651 18.9897 127.391 19.662C127.131 20.3342 126.765 20.9453 126.275 21.48C125.801 22.0147 125.236 22.4272 124.594 22.7327C123.982 23.0536 123.279 23.1911 122.53 23.1911ZM118.693 17.5231C118.693 18.0884 118.785 18.6384 118.968 19.1731C119.151 19.7078 119.411 20.1814 119.748 20.5786C120.084 20.9911 120.497 21.3119 120.971 21.5564C121.445 21.8008 121.964 21.923 122.545 21.923C123.141 21.923 123.677 21.8008 124.15 21.5411C124.624 21.2814 125.022 20.9453 125.358 20.5328C125.694 20.105 125.939 19.6314 126.123 19.112C126.291 18.5925 126.382 18.0578 126.382 17.5231C126.382 16.9578 126.291 16.4078 126.107 15.8884C125.924 15.369 125.664 14.8954 125.312 14.4829C124.976 14.0704 124.563 13.7343 124.089 13.5051C123.615 13.2759 123.096 13.1537 122.53 13.1537C121.934 13.1537 121.399 13.2759 120.925 13.5204C120.451 13.7648 120.053 14.1009 119.717 14.5287C119.381 14.9565 119.136 15.4148 118.953 15.9342C118.785 16.4384 118.693 16.9731 118.693 17.5231Z"
        fill="white"
      />
      <path
        d="M131.213 14.5438V23.1298H129.792V11.9161H130.984L137.833 20.6701V11.9314H139.254V23.1145H137.955L131.213 14.5438Z"
        fill="white"
      />
    </svg>
  );
};

export default PlayStoreIcon;
