import { Button, InputText, Textarea } from "../Ui";
import {
  FacebookIcon,
  InstagramIcon,
  LocationIcon,
  MessageIcon,
} from "../Icons";
import LinkedInIcon from "../Icons/LinkedinIcon";

const Contact = () => {
  return (
    <div id="contact">
      <div className="w-full h-[28rem] relative mt-20">
        <div className="bg-blue/50 backdrop-brightness-50 absolute h-full w-full"></div>
        <iframe
          title="map"
          src="https://maps.google.com/maps?q=21.1882825, 72.8136035&z=16&output=embed"
          width="100%"
          height="100%"
          allowFullScreen={true}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <div className="container mx-auto px-5 lg:px-20 xl:px-20 2xl:px-32 py-20">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-14 max-w-4xl mx-auto">
          <div className="space-y-3">
            <h2 className="font-bold text-4xl mb-5 capitalize text-blue">
              Drop Us a Line
            </h2>
            <div className="grid grid-cols-1">
              <InputText title={"Name"} placeholder={"Your name*"} />
            </div>
            <div className="grid grid-cols-2 gap-5">
              <InputText title={"Email"} placeholder={"Your email*"} />
              <InputText title={"Phone"} placeholder={"Your phone No."} />
            </div>
            <div className="grid grid-cols-1">
              <Textarea title={"Message"} placeholder={"Drop us a line"} />
            </div>
            <div className="grid grid-cols-1">
              <Button width="w-full">Send</Button>
            </div>
          </div>
          <div>
            <h2 className="font-bold text-4xl mb-5 capitalize text-blue">
              Get in Touch
            </h2>
            <div className="space-y-5">
              <div className="flex gap-10 items-center cursor-pointer relative">
                <LocationIcon className="text-blue h-5 w-5 absolute " />{" "}
                <h2 className="text-[#1E0E62] font-bold ml-10">
                  HisabKaro, First Floor, IIM Lucknow Noida Campus, Incubation
                  Cell, Noida Sector 62, UP
                </h2>
              </div>
              <div
                className="flex gap-10 items-center cursor-pointer relative"
                onClick={() => window.open("mailto:Info@hisabkaro.com")}
              >
                <MessageIcon className="text-blue h-5 w-5 absolute" />{" "}
                <h2 className="text-[#1E0E62] font-bold ml-10">
                  Info@hisabkaro.com
                </h2>
              </div>
              <div
                className="flex gap-10 items-center cursor-pointer relative"
                onClick={() =>
                  window.open("https://www.facebook.com/hisabkaro2022")
                }
              >
                <FacebookIcon className="text-blue h-5 w-5 absolute" />{" "}
                <h2 className="text-[#1E0E62] ml-10">Facebook</h2>
              </div>
              <div
                className="flex gap-10 items-center cursor-pointer relative"
                onClick={() =>
                  window.open("https://www.linkedin.com/company/hisabkaro")
                }
              >
                <LinkedInIcon className="text-blue h-5 w-5 absolute" />{" "}
                <h2 className="text-[#1E0E62] ml-10">LinkedIn</h2>
              </div>
              <div
                className="flex gap-10 items-center cursor-pointer relative"
                onClick={() =>
                  window.open("https://instagram.com/hisab_karo?r=nametag")
                }
              >
                <InstagramIcon className="text-blue h-5 w-5 absolute" />{" "}
                <h2 className="text-[#1E0E62] ml-10">Instagram</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
