import React from "react";
import {
  Button,
  ImageUpload,
  InputDate,
  InputText,
  TextEditor,
} from "../Components/Ui";

const BlogCreate = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [blogData, setBlogData] = React.useState("");
  const [blogName, setBlogName] = React.useState("");
  const [autherName, setAutherName] = React.useState("");
  const [blogImage, setBlogImage] = React.useState("");
  const [blogDate, setBlogDate] = React.useState("");

  const onCreateBlog = () => {
    setIsLoading(true);
    const body = {
      name: blogName,
      auther: autherName,
      image: blogImage,
      date: blogDate,
      content: blogData,
    };
    console.log(body);
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);
  };

  return (
    <>
      <section className="container mx-auto px-5 lg:px-20 xl:px-20 2xl:px-32 py-20">
        <div className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
            <InputText
              title={"Blog Name"}
              placeholder={"Blog Name"}
              onChange={(e) => setBlogName(e)}
            />
            <InputText
              title={"Auther Name"}
              placeholder={"Auther Name"}
              onChange={(e) => setAutherName(e)}
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
            <ImageUpload
              title={"Blog Image"}
              onChange={(e) => setBlogImage(e)}
            />
            <InputDate title={"Blog Date"} onChange={(e) => setBlogDate(e)} />
          </div>
          <div className="grid grid-cols-1">
            <TextEditor
              title={"Blog Content"}
              onChange={(e) => setBlogData(e)}
            />
          </div>
          <Button width="36" loading={isLoading} onClick={onCreateBlog}>
            Create Blog
          </Button>
        </div>
      </section>
    </>
  );
};

export default BlogCreate;
