import React from "react";
import { AppStoreIcon, PlayStoreIcon } from "../Icons";

const DownloadApp = () => {
  return (
    <div className="bg-blue">
      <div className="container mx-auto px-5 lg:px-20 xl:px-20 2xl:px-32 pt-5">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <div className="md:flex gap-5 pb-20">
            <div className="text-white mt-auto">
              <h2 className="text-3xl lg:text-4xl xl:text-5xl font-bold mb-4 md:mb-7">
                Download The <br /> App Now
              </h2>
              <p className="max-w-xs text-lg md:text-xl xl:test-2xl mb-5 font-light">
                Download Hisab Karo today to see the benefits and enjoy the
                results faster than any other app out there.
              </p>
              <div className="flex gap-4 items-center">
                {/* <AppStoreIcon
                  className="h-16 w-28 cursor-pointer"
                  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.hk.hisabkaro"
                    )
                  }
                  target="_blank"
                /> */}
                <PlayStoreIcon
                  className="h-16 w-28 cursor-pointer"
                  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.hisabkaro.app"
                    )
                  }
                  target="_blank"
                />
              </div>
            </div>
            <div className="bg-white/2 border border-white/20 rounded p-3 h-fit w-fit mt-auto shadow-lg">
              <img
                className="h-28 w-28 mx-auto"
                src="/img/banner/hzkqrcode.png"
                alt="QR code"
              />
              <p className="text-xs text-white">Scan to Download App!</p>
            </div>
          </div>
          <div className="w-72 md:w-[37rem] lg:w-[32rem] xl:w-[32rem] h-fit mx-auto">
            <img className="w-full h-full" src="/img/features/2.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadApp;
