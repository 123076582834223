import React from "react";
import { useParams } from "react-router-dom";
import { BlogType } from "../../Interface";
import { Markup } from "interweave";
import blog from "../../Content/blog.json";

const DisplayBlog = () => {
  let { id } = useParams();
  const [blogDetails, setBlogDetails] = React.useState<BlogType | undefined>(
    undefined
  );

  React.useEffect(() => {
    const recipename = blog.find((hero) => {
      return hero.id === id;
    });
    setBlogDetails(recipename);
  }, [id]);

  return (
    <div className="container mx-auto px-5 lg:px-20 xl:px-20 2xl:px-32 py-5 lg:py-20 xl:py-20 2xl:py-32">
      <div className="h-96 w-full">
        <img
          className="w-full h-full object-cover rounded"
          src={blogDetails?.img}
          alt={blogDetails?.title}
        />
      </div>
      <div className="my-10 pb-5 border-b border-blue">
        <h2 className="font-bold text-4xl text-[#2D3748] capitalize">
          {blogDetails?.title}
        </h2>
        <div className="flex items-baseline gap-5 my-3">
          <h2 className="font-bold text-xl text-[#2D3748] capitalize">
            Written by {blogDetails?.writer}
          </h2>
          <p className="font-semibold text-lg text-[#718096] capitalize">
            {blogDetails?.date}
          </p>
        </div>
      </div>
      <div className="space-y-5">
        <Markup content={blogDetails?.contant} />
      </div>
    </div>
  );
};

export default DisplayBlog;
