import React from "react";
import { CalendarIcon, BriefcaseIcon, DealIcon } from "../Icons";

const aboutContent = [
  {
    title: "Attendance",
    par: "Carefully crafted precise design, with harmonious typography and perfect padding around every component.",
    icon: CalendarIcon,
  },
  {
    title: "Payroll",
    par: "Built with HTML, CSS and JS. Startup’s code is easy to under-stand and easy to change. No other frameworks needed.",
    icon: BriefcaseIcon,
  },
  {
    title: "Hiring",
    par: "Transform your ideas into reality and launch a beautiful site with minimal frustration.",
    icon: DealIcon,
  },
];

const About = () => {
  return (
    <div className="h-fit bg-white">
      <div className="container mx-auto px-5 lg:px-20 xl:px-20 2xl:px-32 py-20">
        <div className="text-center">
          <h2 className="font-bold text-4xl mb-5 text-[#1E0E62] capitalize">
            What’s New in Hisab Karo?
          </h2>
          {/* <p className="text-[#151439]/40">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra
            nunc ante velit vitae.{" "}
          </p> */}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-10 max-w-5xl mx-auto pt-20">
          {aboutContent.map((list, index) => {
            return (
              <div
                key={index}
                className="border-2 border-[#EBEAED] rounded-md px-6 py-9 grid items-center text-center justify-center"
              >
                <list.icon className="mx-auto" />
                <h2 className="font-bold text-2xl uppercase my-3 text-[#212121]">
                  {list.title}
                </h2>
                {/* <p className="text-[#151439]/40">{list.par}</p> */}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default About;
