import React from "react";
import { Button } from "../Components/Ui";
import { useNavigate } from "react-router-dom";

const AdminLogin = () => {
  const navigate = useNavigate();

  const onLogin = () => {
    const body = {
      name: "HISAB_KARO",
      email: "hisab_karo@gmail.com",
      role: "admin",
    };
    localStorage.setItem("hisab_karo", JSON.stringify(body));
    navigate("/blog_create");
  };

  return (
    <div className="text-center my-10">
      <h2 className="font-bold text-4xl capitalize text-blue my-10">
        Admin Login page
      </h2>
      <Button onClick={onLogin}>Login</Button>
    </div>
  );
};

export default AdminLogin;
