import React from "react";

const Instagram = ({ ...props }) => {
  return (
    <>
      <img src="/img/logo/instagram.png" alt="linked" {...props} />
    </>
  );
};

export default Instagram;
