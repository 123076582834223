import React from "react";

const Story = () => {
  return (
    <div id="about" className="bg-blue">
      <div className="container mx-auto px-5 lg:px-20 xl:px-20 2xl:px-32 py-20">
        <div className="grid grid-cols-1 md:grid-cols-2 h-auto lg:h-[29rem]">
          <div>
            <img src="/img/story/1.png" alt="" />
          </div>
          <div className="bg-white flex justify-center items-center">
            <div className="px-10">
              <h2 className="font-bold text-blue text-4xl mb-5 capitalize">
                Our Story will connect you.
              </h2>
              <p className="text-[#151439]/40">
                Over 63 millions #MSME in India, employee over 110 million
                people! Our integrated AI based #EnterpriseTech solution will
                help save over 1 billion manhours which translates into 63
                billion rupees monthly! Our #FinTech solution will help the 110
                million workers access to credit.
                <br />
                <br />
                #HisabKaro is founded by Viral Pathak, who is an alumni of IIM
                Lucknow and has 10 years of experience in business development
                and management consulting. Pallavi is Co founder, and has a
                strong technology experience of 9 years.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Story;
