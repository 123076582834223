import React from "react";
import DisplayBlog from "../Components/Blog/DisplayBlog";
import DownloadApp from "../Components/Home/DownloadApp";

const Blog = () => {
  return (
    <>
      <DisplayBlog />
      <DownloadApp />
    </>
  );
};

export default Blog;
