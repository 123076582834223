import React from "react";

const Features = () => {
  return (
    <>
      <div id="work" className="h-[25rem] bg-blue">
        <div className="container mx-auto px-5 lg:px-20 xl:px-20 2xl:px-32 py-20">
          <div className="text-center text-white max-w-5xl mx-auto">
            <h2 className="font-bold text-4xl mb-5 capitalize">
              How to use Hisab Karo perfectly
            </h2>
            {/* <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra
              nunc ante velit vitae. Est tellus vitae, nullam lobortis enim.
              Faucibus amet etiam tincidunt rhoncus, ullamcorper velit.
              Ullamcorper risus tempor, ac nunc libero urna, feugiat.
            </p> */}
          </div>
          <div className="h-48 md:h-64 w-auto max-w-2xl mx-auto relative md:mt-20">
            <video width="750" height="500" controls autoPlay muted loop> <source src="/img/HisabKaro_video.mp4" type="video/mp4" /></video>
            {/* <img className="h-full w-full" src="/img/about/1.png" alt="" />
            <div className="h-16 w-16 bg-white/30 rounded-full flex justify-center items-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <div className="h-14 w-14 bg-white/50 rounded-full flex justify-center items-center">
                <div className="h-10 w-10 bg-white rounded-full flex justify-center items-center">
                  <PlayIcon className="text-blue" />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div id="feature" className=" mx-auto py-20 pt-44">
        <div className="text-center">
          <h2 className="font-bold text-4xl mb-5 text-blue capitalize">
            App features
          </h2>
          {/* <p className="text-[#000000]/70">
            Huge trendy collection od web screens and components. Fully
            customized.
          </p> */}
        </div>
        {/* <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-0 md:gap-10 pt-10">
          <div className="text-center md:text-right m-auto">
            <div className="my-6 md:my-14">
              <h2 className="font-bold text-xl text-blue uppercase">
                Pixel Perfect Design
              </h2>
              <p className="text-[#000000]/70 max-w-[12rem] mx-auto md:mx-0 md:ml-auto">
                This product will speed up your work in creating landing pages.
              </p>
            </div>
            <div className="my-6 md:my-14">
              <h2 className="font-bold text-xl text-blue uppercase">
                Free Google Fonts
              </h2>
              <p className="text-[#000000]/70 max-w-[12rem] mx-auto md:mx-0 md:ml-auto">
                We used only time-tested technologies for the best results.
              </p>
            </div>
          </div>
          <div>
            <img src="/img/features/1.png" alt="" />
          </div>
          <div className="text-center md:text-left m-auto">
            <div className="my-6 md:my-14">
              <h2 className="font-bold text-xl text-blue uppercase">
                Modern & Bright Colors
              </h2>
              <p className="text-[#000000]/70 max-w-[12rem] mx-auto md:mx-0 md:mr-auto">
                At length one of them called out in a clear, polite, smooth
                dialect.
              </p>
            </div>
            <div className="my-6 md:my-14">
              <h2 className="font-bold text-xl text-blue uppercase">
                Carefully Named Layers
              </h2>
              <p className="text-[#000000]/70 max-w-[12rem] mx-auto md:mx-0 md:mr-auto">
                At first, for some time, I was not able to answer him one word.
              </p>
            </div>
          </div>
        </div> */}
        <div>
          <img src="/img/features/3.png" alt="" />
        </div>
      </div>
    </>
  );
};

export default Features;
