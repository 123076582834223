import React from "react";

const MessageIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="currentColor"
      {...props}
    >
      <path
        d="M18 5.10315V13.0786C18 13.5206 17.8426 13.8989 17.5279 14.2136C17.2132 14.5284 16.8348 14.6857 16.3929 14.6857H1.60714C1.16518 14.6857 0.78683 14.5284 0.472098 14.2136C0.157366 13.8989 0 13.5206 0 13.0786V5.10315C0.294643 5.43127 0.632813 5.72257 1.01451 5.97703C3.43862 7.62435 5.10268 8.77949 6.0067 9.44243C6.38839 9.72368 6.69643 9.94467 6.9308 10.1054C7.17188 10.2594 7.48996 10.4201 7.88504 10.5875C8.28013 10.7482 8.64844 10.8286 8.98996 10.8286H9H9.01005C9.35156 10.8286 9.71987 10.7482 10.115 10.5875C10.51 10.4201 10.8248 10.2594 11.0592 10.1054C11.3002 9.94467 11.6116 9.72368 11.9933 9.44243C13.1317 8.61877 14.7991 7.46364 16.9955 5.97703C17.3772 5.71587 17.7121 5.42458 18 5.10315ZM18 2.15002C18 2.67904 17.8359 3.18462 17.5078 3.66677C17.1797 4.14891 16.7712 4.56074 16.2824 4.90226C13.7645 6.65002 12.1975 7.73819 11.5815 8.16677C11.5145 8.21364 11.3705 8.31744 11.1496 8.47815C10.9353 8.63217 10.7545 8.7594 10.6071 8.85985C10.4665 8.9536 10.2924 9.06074 10.0848 9.18127C9.88393 9.30181 9.69308 9.39221 9.51228 9.45248C9.33147 9.51275 9.16406 9.54288 9.01005 9.54288H9H8.98996C8.83594 9.54288 8.66853 9.51275 8.48772 9.45248C8.30692 9.39221 8.11272 9.30181 7.90513 9.18127C7.70424 9.06074 7.53013 8.9536 7.38281 8.85985C7.24219 8.7594 7.06138 8.63217 6.8404 8.47815C6.62612 8.31744 6.48549 8.21364 6.41853 8.16677C5.80915 7.73819 4.93192 7.12882 3.78683 6.33864C2.64174 5.54177 1.95536 5.06297 1.72768 4.90226C1.3125 4.62101 0.920759 4.23596 0.552455 3.74712C0.184152 3.25159 0 2.79288 0 2.37101C0 1.84869 0.137277 1.41342 0.41183 1.0652C0.69308 0.716988 1.09152 0.542881 1.60714 0.542881H16.3929C16.8281 0.542881 17.2031 0.700247 17.5179 1.01498C17.8393 1.32971 18 1.70806 18 2.15002Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MessageIcon;
