import React from "react";
import { Footer } from "../Components/Common";
import About from "../Components/Home/About";
import Banner from "../Components/Home/Banner";
import Blog from "../Components/Home/Blog";
import Contact from "../Components/Home/Contact";
import DownloadApp from "../Components/Home/DownloadApp";
import Features from "../Components/Home/Features";
import Story from "../Components/Home/Story";

const Landing = () => {
  return (
    <>
      <Banner />
      <About />
      <Features />
      <Story />
      <Blog />
      <Contact />
      <DownloadApp />
      <Footer />
    </>
  );
};

export default Landing;
