import React from "react";

const LinkedInIcon = ({ ...props }) => {
  return (
    <>
      <img src="/img/logo/linkedin.png" alt="linked" {...props} />
    </>
  );
};

export default LinkedInIcon;
