import React from "react";

const CalendarIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      {...props}
    >
      <path
        d="M16 4V10"
        stroke="#316457"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32 4V10"
        stroke="#316457"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 15C6 10.5817 9.58172 7 14 7H34C38.4183 7 42 10.5817 42 15V36C42 40.4183 38.4183 44 34 44H14C9.58172 44 6 40.4183 6 36V15Z"
        fill="#B3C6C1"
        stroke="#316457"
        strokeWidth="3"
      />
      <path
        d="M18.937 28.8287C18.2901 28.3112 17.3462 28.4161 16.8287 29.063C16.3112 29.7099 16.4161 30.6538 17.063 31.1713L18.937 28.8287ZM31.1289 26.9878C31.6744 26.3643 31.6112 25.4167 30.9878 24.8711C30.3643 24.3256 29.4167 24.3888 28.8711 25.0122L31.1289 26.9878ZM21.5056 32.8045L20.5686 33.9758L21.5056 32.8045ZM17.063 31.1713L20.5686 33.9758L22.4427 31.6332L18.937 28.8287L17.063 31.1713ZM25.3891 33.5475L31.1289 26.9878L28.8711 25.0122L23.1313 31.572L25.3891 33.5475ZM20.5686 33.9758C22.0312 35.1459 24.1557 34.9571 25.3891 33.5475L23.1313 31.572C22.9551 31.7734 22.6516 31.8004 22.4427 31.6332L20.5686 33.9758Z"
        fill="#316457"
      />
      <path
        d="M6 18H42"
        stroke="#316457"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CalendarIcon;
