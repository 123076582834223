import React from "react";
import { Toaster } from "react-hot-toast";
import AppRoutes from "./Routes/AppRoutes";
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./Lib/Hooks/ScrollToTop";

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <AppRoutes />
        <Toaster position="top-center" reverseOrder={false} />
      </Router>
    </>
  );
}

export default App;
