import React from "react";
import { HashLink } from "react-router-hash-link";
import { ForwardIcon } from "../../Icons";

const navigation = [
  {
    title: "Home",
    path: "#home",
  },
  {
    title: "How it works",
    path: "#work",
  },
  {
    title: "Feature",
    path: "#feature",
  },
  {
    title: "About Us",
    path: "#contact",
  },
  {
    title: "Blogs",
    path: "#contact",
  },
  {
    title: "Contact US",
    path: "#contact",
  },
];

const Footer = () => {
  return (
    <div className="bg-blue text-white px-5 lg:px-20 xl:px-20 2xl:px-32 py-10 border-t-2 border-white/50">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-14">
        <div className="text-white">
          <img className="h-48 w-48" src="/img/banner/logo.png" alt="logo" />
        </div>
        <div>
          <h2 className="font-bold text-xl uppercase mb-2">quick link</h2>
          {navigation.map((item, index) => {
            return (
              <p key={index} className="capitalize mb-0.5">
                <HashLink key={index} smooth to={`${item?.path}`}>
                  {item?.title}
                </HashLink>
              </p>
            );
          })}
        </div>
        <div>
          <h2 className="font-bold text-xl uppercase mb-2">Address</h2>
          <p className="font-bold space-x-5 md:space-x-1">
            <span onClick={() => window.open("mailto:Info@hisabkaro.com")}>
              info@HisabKaro.com
            </span>{" "}
            {/*<span>+91 - 635 6365973</span> */}
          </p>
          <p>
            HisabKaro, First Floor, IIM Lucknow Noida Campus, Incubation Cell,
            Noida Sector 62, UP
          </p>
        </div>
        <div>
          <h2 className="font-bold text-xl uppercase mb-2">news letter</h2>
          <p>Subscribe our newsletter to get our latest update.</p>
          <div className="relative mt-2 w-full xl:w-auto">
            <ForwardIcon className="absolute bg-blue h-8 w-8 rounded-tr rounded-br right-1 bottom-1 p-2 cursor-pointer" />
            <input
              type="text"
              name="newsLetter"
              id="newsLetter"
              className="w-full p-2 rounded h-10 text-black"
              placeholder="Your email address"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
