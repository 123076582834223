import React from "react";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useOutsideClick } from "../../../Lib/Hooks";
import { FacebookIcon, HamburgerIcon, InstagramIcon } from "../../Icons";

const navigation = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "How it works",
    path: "#work",
  },
  {
    title: "Feature",
    path: "#feature",
  },
  {
    title: "About Us",
    path: "#about",
  },
  {
    title: "Blogs",
    path: "#blog",
  },
  {
    title: "Contact US",
    path: "#contact",
  },
];

const Navbar = () => {
  const ref = React.useRef<any>();
  let navigate = useNavigate();
  const [isToggleOpen, setIsToggleOpen] = React.useState(false);

  useOutsideClick(ref, () => {
    if (isToggleOpen) {
      setIsToggleOpen(false);
    }
  });

  return (
    <>
      <div ref={ref} className="bg-[#366A5D] w-full h-fit px-5 py-5">
        {isToggleOpen && (
          <div className="absolute top-0 left-0 h-screen w-52 bg-blue shadow-2xl border-r-2 border-white/40 text-white z-50">
            <div className="px-7 py-10 space-y-7">
              <div className="text-white">
                <img
                  className="h-24 w-24 cursor-pointer"
                  src="/img/banner/logo.png"
                  alt=""
                  onClick={() => navigate("/")}
                />
              </div>
              <ul className="grid space-y-5 text-white">
                {navigation.map((item, index) => {
                  return (
                    <li key={index} className="">
                      <HashLink
                        key={index}
                        smooth
                        to={`${item?.path}`}
                        onClick={() => setIsToggleOpen(false)}
                      >
                        {item?.title}
                      </HashLink>
                    </li>
                  );
                })}
                <li
                  key={"login"}
                  className=" cursor-pointer"
                  onClick={() => window.open("http://web.hisabkaro.com")}
                >
                  Login
                </li>
              </ul>
            </div>
          </div>
        )}
        <div className="lg:hidden block">
          <HamburgerIcon
            className="text-white h-7 w-7"
            onClick={() => setIsToggleOpen(true)}
          />
        </div>
        <div className="hidden lg:flex justify-between items-center">
          <img
            className="h-24 w-24 cursor-pointer"
            src="/img/banner/logo.png"
            alt=""
            onClick={() => navigate("/")}
          />
          <div className="flex items-center gap-10">
            <ul className="flex items-center gap-14 text-white font-bold">
              {navigation.map((item, index) => {
                return (
                  <li key={index} className="flex">
                    <HashLink key={index} smooth to={`${item?.path}`}>
                      {item?.title}
                    </HashLink>
                  </li>
                );
              })}
              <li
                key={"login"}
                className="flex cursor-pointer"
                onClick={() => window.open("http://web.hisabkaro.com")}
              >
                Login
              </li>
            </ul>
            <div className="flex items-center gap-5 text-white">
              <div className="h-0.5 w-24 bg-white/30"></div>
              <FacebookIcon
                onClick={() =>
                  window.open("https://www.facebook.com/hisabkaro2022")
                }
                target="_blank"
              />
              <InstagramIcon
                onClick={() =>
                  window.open("https://instagram.com/hisab_karo?r=nametag")
                }
                target="_blank"
                className=" h-5 w-5"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
