import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = () => {
  const authData = JSON.parse(localStorage.getItem("hisab_karo")!);

  return authData !== null ? <Outlet /> : <Navigate to={"/auth"} />;
};

export default ProtectedRoute;
