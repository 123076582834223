import React from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  title: string;
  onChange?: (...args: any[]) => any;
}

const TextEditor: React.FC<InputProps> = (props) => {
  const { title, onChange } = props;
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );

  const onEditorStateChange = (editorState: any) => {
    setEditorState(editorState);

    if (onChange) {
      onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    }
    // return input.onChange(
    //   draftToHtml(convertToRaw(editorState.getCurrentContent()))
    // );
  };

  return (
    <>
      <p className="text-blue font-bold text-xs grid my-2">{title}</p>
      <div className="border border-blue/50 p-2 rounded">
        <Editor
          editorState={editorState}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          onEditorStateChange={onEditorStateChange}
        />
      </div>
    </>
  );
};

export default TextEditor;
