import React from "react";
import { Navbar } from "../Common";
import { AppStoreIcon, PlayStoreIcon } from "../Icons";

const Banner = () => {
  return (
    <div
      id="home"
      className="h-fit lg:h-screen bg-blue bg-banner_bg bg-contain bg-no-repeat bg-[center_top_8rem] grid"
    >
      <Navbar />
      <div className="container mx-auto mt-auto pt-20 md:pt-0">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
          <div className="relative  my-auto mx-auto z-10">
            <div className="bg-white w-72 md:w-[30rem] lg:w-[28rem] xl:w-[31rem] 2xl:w-[35rem] h-96 rounded-lg p-4">
              <div className="">
                <h2 className="font-light text-2xl lg:text-4xl xl:text-4xl 2xl:text-5xl text-blue mb-2 uppercase">
                  The comprehensive business
                  <span className="font-bold"> solution app </span>
                </h2>
                <p className="text-[#6C6C72] text-lg md:text-xl xl:test-2xl max-w-md">
                  Hisab Karo (HisabKaro) is one stop solution for all your
                  business related problem. Now, Hisab Karo will help you manage
                  your HR related issues!
                </p>
              </div>
              <div
                className="mt-6"
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.hisabkaro.app"
                  )
                }
              >
                <h2 className="text-blue uppercase font-bold text-lg md:text-xl xl:test-2xl mb-1">
                  Download App Now
                </h2>
                <div className="flex gap-5">
                  {/* <AppStoreIcon className="w-36" /> */}
                  <PlayStoreIcon className="w-36" />
                </div>
              </div>
            </div>
            <div className="w-72 md:w-[30rem] lg:w-[28rem] xl:w-[31rem] 2xl:w-[35rem] h-[29rem] border-[10px] rounded-lg border-white bg-transparent absolute -top-10 -right-6 md:-right-10 -z-10"></div>
          </div>
          <div className="w-72 md:w-[37rem] lg:w-[32rem] xl:w-[32rem] h-fit mx-auto mt-16 lg:mt-0">
            <img className="h-full w-full" src="/img/banner/1.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
