import React from "react";
import BlogSlider from "./BlogSlider";
import { useNavigate } from "react-router-dom";
import blog from "../../Content/blog.json";

const Blog = () => {
  const navigate = useNavigate();

  return (
    <>
      <div
        id="blog"
        className="container mx-auto px-5 lg:px-20 xl:px-20 2xl:px-32 py-20"
      >
        <div className="text-center">
          <h2 className="font-bold text-4xl mb-5 text-blue capitalize">
            Our recent blog
          </h2>
        </div>
      </div>
      <BlogSlider>
        {blog?.map((list, index) => {
          return (
            <div
              key={index}
              className="h-56 md:h-72 w-96 md:w-[35rem] relative pr-2 cursor-pointer"
              onClick={() => navigate(`/blog/${list?.id}`)}
            >
              <img className="h-full w-full" src={list?.img} alt="" />
              <div className="text-center text-white absolute bottom-0 p-5 bg-black/30 w-[98%]">
                <h2 className="font-bold">{list?.title}</h2>
                <p>{list?.date}</p>
              </div>
            </div>
          );
        })}
      </BlogSlider>
    </>
  );
};

export default Blog;
