import React from "react";
import { Route, Routes } from "react-router-dom";
import { Layout } from "../Components/Common";
import Landing from "../Pages";
import AdminLogin from "../Pages/AdminLogin";
import Blog from "../Pages/Blog";
import BlogCreate from "../Pages/BlogCreate";
import ProtectedRoute from "./ProtectedRoute";

const AppRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route element={<Layout />}>
          <Route path="/blog/:id" element={<Blog />} />
          <Route path="/auth" element={<AdminLogin />} />
          {/* PROTECTED ROUTE */}
          <Route path="/blog_create" element={<ProtectedRoute />}>
            <Route
              path="/blog_create"
              element={
                <React.Suspense>
                  <BlogCreate />
                </React.Suspense>
              }
            />
          </Route>
        </Route>
      </Routes>
    </>
  );
};

export default AppRoutes;
