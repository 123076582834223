import React from "react";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  title: string;
  placeholder?: string;
  value?: string;
  onChange?: (...args: any[]) => any;
  onKeyPress?: (...args: any[]) => any;
}

const Textarea: React.FC<InputProps> = (props) => {
  const { title, placeholder, onChange, value } = props;

  const handleOnChange = (e: any) => {
    if (onChange) {
      onChange(e.target.value);
    }
    return null;
  };

  return (
    <label htmlFor={title} className="text-blue font-bold text-xs grid">
      {title}
      <textarea
        name={title}
        id={title}
        value={value}
        placeholder={placeholder}
        onChange={handleOnChange}
        className="border-2 border-[#EBEAED] p-2 h-28 w-full rounded focus:outline-none my-2"
      ></textarea>
    </label>
  );
};

export default Textarea;
