import React from "react";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  title: string;
  value?: string;
  placeholder?: string;
  onChange?: (...args: any[]) => any;
}

const InputText: React.FC<InputProps> = (props) => {
  const { className, title, placeholder, children, onChange, value, ...rest } =
    props;

  const handleOnChange = (e: any) => {
    if (onChange) {
      onChange(e.target.value);
    }
    return null;
  };

  return (
    <label htmlFor={title} className="text-blue font-bold text-xs grid h-fit">
      <p className="text-blue font-bold text-xs grid">{title}</p>
      <input
        {...rest}
        id={title}
        name={title}
        type={"datetime-local"}
        autoCapitalize="off"
        value={value}
        placeholder={placeholder}
        onChange={handleOnChange}
        className="border-2 border-[#EBEAED] p-2 rounded focus:outline-none my-2"
      />
    </label>
  );
};

export default InputText;
